import React, { useState } from 'react';
import TopLine from '@rambler-components/topline';
import Modal from '@rambler-components/modal';
import Geo from '@rambler-components/geo';
import Header from '@rambler-components/header';
import Menu from '../Menu';

import '@rambler-components/topline/styles.css?compiled';
import '@rambler-components/modal/styles.css?compiled';
import '@rambler-components/geo/styles.css?compiled';
import '@rambler-components/header/styles.css?compiled';

import s from './style.css';
import './style.compiled.css?compiled';

export default function HeaderComponent(props) {
  const [userFetched, setUserFetched] = useState(false);
  const [userProfile, setUserProfile] = useState(undefined);
  const [isGeoOpened, setGeoOpened] = useState(false);
  const [geoKey, setGeoKey] = useState();

  const onSearch = (query) => {
    window.location = `https://nova.rambler.ru/search?query=${encodeURIComponent(
      query
    )}`;
  };

  const onGeoSave = (geo) => {
    setGeoKey(geo.id);
  };

  return (
    <>
      <>
        <TopLine
          projectCode="soft"
          advert={<ToplineAd />}
          withSearch={true}
          onSearchSubmit={onSearch}
          currentServerTime={parseInt(props.currentServerTime)}
          onUserFetched={setUserFetched}
          onUserInfo={setUserProfile}
          breakpointsClassName={s.topline_breakpoints}
          onOpenGeo={() => setGeoOpened(true)}
          reloadGeoKey={geoKey}
        />
        <Modal
          width={560}
          isOpen={isGeoOpened}
          onClose={() => setGeoOpened(false)}
        >
          <Geo
            reloadKey={geoKey}
            onChange={onGeoSave}
            onClose={() => setGeoOpened(false)}
          />
        </Modal>
      </>
      <div className={s.header}>
        <Header
          projectCode="soft"
          withoutShadow={true}
          userFetched={userFetched}
          userProfile={userProfile}
        >
          <>
            <Menu
              location={props.path}
              stat={{ appPlatformType: props.path ? props.path : 'all' }}
            />
          </>
        </Header>
      </div>
    </>
  );
}

/**
 * Рекламный блок в топлайне
 * Документация по рекламным кодам SSP https://confluence.rambler-co.ru/pages/viewpage.action?pageId=35701075
 */
function ToplineAd() {
  return (
    <div id="block_pad_id_549396255_547613113">
      <Helmet>
        <link
          rel="preload"
          href="//ssp.rambler.ru/capirs_async.js"
          as="script"
        />
        <script
          type="text/javascript"
          src="//ssp.rambler.ru/capirs_async.js"
          async
        />
      </Helmet>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(){
            window.begun_new_callbacks = window.begun_new_callbacks || [];
            window.begun_new_callbacks.push({ "lib": { "init": initializer } });
            function initializer() {
              Adf.banner.ssp('block_pad_id_549396255_547613113', {},{
                "begun-auto-pad": "547613113",
                "begun-block-id": "549396255"
              });
            }
          })()`,
        }}
      />
    </div>
  );
}

